.list-days {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-self: center;
    width: 81vw;
    padding-bottom: 10px;
}

@media screen and (max-width: 599px) {
    .list-days {
        padding-top: 10px;
        width: calc(100% - 10px);
    }
}