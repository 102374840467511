.page__text {
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    font-size: 16px;
    margin: 0;
}

.page__text:nth-child(2) {
    margin-top: 10px;
}