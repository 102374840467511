.search-form__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    padding: 5px 5px;
    border: 1px solid black;
    border-radius: 15px;
}

@media screen and (max-width: 580px) {
    .search-form__container {
    
    } 
}
