.page__imageWA {
    width: 40px;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 599px) {
    .page__imageWA {
        width: 35px;
    }
}