.formadd__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    height: 36px;
    border-radius: 0 9px 9px 0;
    padding: 10px 30px 0 29px;
}

@media screen and (max-width: 580px) {
    .formadd__checkbox {
        padding: 0 20px 0 19px;
        background-color: transparent;
    }
}