.formadd__input {
    margin: 0 36px 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 0 5px 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 358px;
    max-height: 14px;
    background-color: transparent !important;
    text-align: left;
}

.formadd__input::placeholder {
    color: #000000;
}

@media screen and (max-width: 450px) {
    .formadd__input {
        margin-left: 22px;
    }
}