.abonements-info__table-column {
    display: flex;
    flex-direction: column;
    width: 156px;
}

@media screen and (max-width: 599px) {
    .abonements-info__table-column {
        width: max-content;
    }
}
