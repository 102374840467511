@font-face {
    font-family: 'Instagramm';
    font-weight: 400;
    src: url("./InstagramSans-Regular.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Instagramm';
    font-weight: 500;
    src: url("./Inter-Medium.woff") format("woff");
  }
  
  @font-face {
    font-family: 'Instagramm';
    font-weight: 900;
    src: url("./InstagramSans-Bold.woff") format("woff");
  }