.trainer-page__info {
    margin: 0;
    Font-family: 'apple', Arial, sans-serif;
    /* font-style: italic; */
    font-size: 13px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0 20px;
    padding: 10px 0 0 0;
}