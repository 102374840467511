.formadd {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(37, 37, 37, 0.5) !important;
    /* Прозрачный фон */
    backdrop-filter: blur(10px) !important;
    /* Эффект размытия для матового стекла */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
}

@media screen and (max-width: 450px) {
    .formadd {
        justify-items: center;
    }
}