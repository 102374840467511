.timetable__element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
}

.timetable__element:nth-child(2) {
    padding-top: 5px;
}