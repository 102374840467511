.admin-page__title {
    padding-top: 30px;
    margin-bottom: 5px;
    padding-left: 10px;
    font-size: 39px;
}


@media screen and (max-width: 700px) {
    .admin-page__title {
        font-size: 20px;
        margin-bottom: 10px;
    }
}