.qr-reader {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(37, 37, 37, 0.512) !important;
    /* Прозрачный фон */
    backdrop-filter: blur(10px) !important;
    /* Эффект размытия для матового стекла */
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.5s linear;
}