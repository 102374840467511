.about-client-page-title {
    margin: 0;
    Font-family: 'et-book', Arial, sans-serif;
    /* font-style: italic; */
    font-size: 13px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 3px;
    padding: 10px 0 0 0;
}