.search-form__input-button {
    background-color: transparent;
    height: 100%;
    border: none;
    padding: 0 10px;
    transition: opacity 1s;
}

.search-form__input-button:hover {
    opacity: 0.5;
    cursor: pointer;
}

@media screen and (max-width: 582px) {
    .search-form__input-button {
       
    }
}
