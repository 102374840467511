.timetable {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
}

@media screen and (max-width: 599px) {
    .timetable {
        padding-top: 0px;
    }
}