.timetable__list-element {
    background-color: transparent;
    width: 81vw;
    max-width: 700px;
    min-height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    margin: 0;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    transition: background-color 0.8s;
}

.timetable__list-element:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1) !important;
}

@media screen and (max-width: 599px) {
    .timetable__list-element {
        min-height: 10px;
        margin: 0;
    }
}