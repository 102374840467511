.formadd__input-select {
    max-height: max-content !important;
    line-height: 14px;
    text-align: left;
    padding: 0 0 3px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background-image: url('./../../../images/arrow-down-black.svg');
    background-size: 20px 20px;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: transparent !important;
}
