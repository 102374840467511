.oneOfMainPage__button {
    position: absolute;
    opacity: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
}

.oneOfMainPage__button:hover {
    cursor: pointer;
    opacity: 0.2;
    background-color: rgb(21, 21, 21);
}