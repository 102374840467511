@font-face {
    font-family: 'bebas-regular';
    font-weight: 800;
    src: url("./BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'bebas-regular';
    font-weight: 400;
    src: url("./BebasNeueRegular.woff") format("truetype");
}