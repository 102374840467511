.formadd__text-error {
    margin: 5px 36px 0;
    height: 30px;
    border: 0;
    /* color: rgba(170, 54, 54, 0.5); */
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0 0 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    opacity: 1;
}

@media screen and (max-width: 450px) {
    .formadd__text-error {
        margin-left: 22px;
    }
}