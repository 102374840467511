.formadd__title {
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding: 34px 0 54px 36px;
    max-height: 24px;
}

@media screen and (max-width: 450px) {
    .formadd__title {
        padding: 25px 22px 35px 22px;
        font-size: 18px;
        line-height: 22px;
    }
}