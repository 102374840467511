.dark__buttons {
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 20px !important;
    transition: box-shadow 0.8s !important;
    margin: 0 0 10px !important;
    border-radius: 50px;
    background-color: black;
    color: #ffffffe6 !important;
    width: 200px !important;
    text-align: center !important;
}

.dark__buttons:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
    background-color: #83705f;
    border-color: #83705f !important;
}