.page-loading__name {
    color: rgb(241, 212, 206);
    /* border: none; */
    text-decoration: none;
    font-family: 'et-book', 'Cormorant_Garamond';
    font-weight: 400;
    letter-spacing: 13px;
    font-size: 20px;
    padding: 0 7px 7px 0;
    margin: 65vh auto 0;
    /* height: 33px; */
    display: table-cell;
    transition: color 1s;
}