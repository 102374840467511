.formadd__checkbox-button {
    width: 36px;
    height: 20px;
    border-radius: 20px;
    border: none;
    background-repeat: no-repeat;
    background-position: top -3px left -4px;
    cursor: pointer;
    background-image: url(./../../../images/tumb__COLOR_tumbler-on-2.svg);
    transition: background-position 1s, background-color 1s, background-image 1s, box-shadow 2s;
}