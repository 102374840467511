.settings-abonements__form{
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 430px;;
}

@media screen and (max-width: 450px) {
    .settings-abonements__form {
        width: 282px;
    }
}