.client-info__quantityVisits {
    font-family: 'bebas-regular';
    font-weight: 400;
    color: white;
    margin: 0;
    font-size: 70px;
    padding: 0;
    height: 70px;
    position: absolute;
    right: 0px;
    top: 80px;
}
