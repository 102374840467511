.info-tooltip__close-button {
    margin-left: auto;
    padding-bottom: 10px;
    width: 32px;
    height: 32px;
    position: absolute;
    right: -39px;
    top: -39px;
    background-image: url(./../../../images/CloseIcon.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
}

.info-tooltip__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .info-tooltip__close-button {
        right: -30px;
        top: -30px;
        position: absolute;
        width: 20px;
        height: 20px;
        margin-bottom: 16px;
    }
}