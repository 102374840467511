.infotab__button-close {
    background-image: url(./../../../images/CloseIcon.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 22px !important;
    height: 22px !important;
    margin: 22px 22px 0 0px;
    /* background-color: rgba(255, 255, 255, 0.512); */
    /* background-color: rgba(37, 37, 37, 0.182) !important;
    backdrop-filter: blur(10px) !important;  */
}