.formadd__button {
    width: 358px;
    height: 50px;
    background-color: #000000;
    border: 0;
    border-radius: 2px;
    margin: 18px auto 35px;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 0;
    transition: background-color 0.8s;

}

.formadd__button:hover {
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .formadd__button {
        width: 238px;
        height: 46px;
        margin: 35px auto 25px;
        padding: 0;
        font-size: 14px;
        line-height: 17px;
    }
}

.formadd__button:disabled {
    opacity: 0.15;
}