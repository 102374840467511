.qr-reader__button {
    color: black;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    transition: opacity 0.7s;
}

.qr-reader__button:hover {
    cursor: pointer;
    opacity: 0.7;
}