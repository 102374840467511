.oneOfMainPage__title {
    font-family: 'et-book' !important;
    font-weight: 400;
    letter-spacing: 57px;
    font-size: 70px;
    color:white;
    margin-right: -57px;
}

@media screen and (max-width: 799px) {
    .oneOfMainPage__title {
        font-size: 60px;
        letter-spacing: 37px;
        margin: 0;
    }
}

@media screen and (max-width: 599px) {
    .oneOfMainPage__title {
        font-size: 40px;
        letter-spacing: 17px;
        margin: 0;
    }
}
