.infotab__description {
    width: 80vw;
    Font-family: 'apple', Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1px;
    text-align: left;
    padding: 10px 0 0;
    margin: 0;
    color: #ffffffe6;
}