.page__imageInfoTab {
    width: 49px;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 599px) {
    .page__imageInfoTab {
        width: 42px;
    }
}