 .formadd__text-input-info {
     margin: 5px 36px 0;
     height: 30px;
     border: 0;
     color: rgba(0, 0, 0, 0.5);
     padding: 0 0 0 0;
     font-family: 'Inter', Arial, sans-serif;
     font-weight: 400;
     font-size: 12px;
     line-height: 15px;
     opacity: 1;
 }

 @media screen and (max-width: 450px) {
     .formadd__text-input-info {
         margin-left: 22px;
     }
 }