.about-client-page__button {
    width: 150px !important;
    margin-right: 10px;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    display: block;
}

.about-client-page__button:nth-child(1) {
    margin-right: 20px !important;
}

.about-client-page__button:nth-child(2) {
    margin-right: 20px !important;
}