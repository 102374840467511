.page__title {
    margin: 0;
    font-family: 'et-book';
    font-weight: 400;
    letter-spacing: 25px;
    font-size: 30px;
    text-align: center;
    padding: 50px 0 0;
}

.page__title:nth-child(1) {
    padding-right: 10px;
}

@media screen and (max-width: 599px) {
    .page__title {
        /* letter-spacing: 19px;
        font-size: 43px;
        padding-top: 50px; */
    }
}