.trainer-page__info-text {
    margin: 0;
    Font-family: 'apple', Arial, sans-serif;
    /* font-style: italic; */
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 3px;
    padding: 5px 0 0 0;
}