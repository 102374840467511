.page__subscription-information {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
    padding: 0 10px 0 0px;
    justify-content: left;
}

@media screen and (max-width: 599px) {
    .page__subscription-information {}
}