.search-form__input {
    background-color: transparent;
    border: none;
    color: #000000;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 582px) {
    .search-form__input {
        /* padding-left: 19px; */
    }
}