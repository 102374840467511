.formadd__checkbox-button-name {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 14px;
    margin: 0;
}

@media screen and (max-width: 769px) {
    .formadd__checkbox-button-name {
        padding: 0 0 0 15px;
    }
}