.page__button {
    border: 0;
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
    padding-top: 18px;
    width: max-content;
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: opacity 0.8s;
}

.page__button:hover {
    opacity: 0.7;
}