.comments-table__textarea {
    width: 95vw;
    min-height: 50px;
    border: 1px solid black;
    padding: 0;
    margin: 0 0 30px 0;
}

.comments-table__textarea::placeholder {
    Font-family: 'Inter', Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 10px 0 0 19px;
    color: black;
}