.not-found-page {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible;
    background-color: rgb(58, 58, 58);
}