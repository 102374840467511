.qr-reader__close-button {
    background-image: url(./../../../images/CloseIcon.svg);
    width: 32px;
    height: 32px;
    margin-left: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
}