.abonements-list__text {
    font-family: 'Inter', Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin: 0;
    height: 18px;
}