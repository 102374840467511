.infotab__description-title {
    Font-family: 'apple', Arial, sans-serif;
    font-size: 15px;
    font-weight: 900;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    padding: 30px 0 30px;
    margin: 0;
    color: #ffffffe6;
}