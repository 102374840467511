.infotab_visible {
    left: 0;
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 521px) {
    .infotab_visible {
        left: 0;
    }
}