.abonements-info__title {
    margin: 0;
    Font-family: 'apple', Arial, sans-serif;
    font-size: 13px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 0 3px;
    padding: 10px 0 0 0;
}