.mainPage__link-page {
    color: rgb(241,212,206);
    /* border: none; */
    border-right: 1px solid #ffffffe6;
    text-decoration: none;
    font-family: 'et-book', 'Cormorant_Garamond';
    letter-spacing: 13px;
    font-weight: 400;
    font-size: 20px;
    padding: 0 7px 7px 0;
    margin: 0;
    /* height: 33px; */
    display: table-cell;
}
