.timetable__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding-top: 30px;
}

@media screen and (max-width: 599px) {
    .timetable__list {
        padding-top: 30px;
    }
}