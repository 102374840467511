.window-clients-control__close-button {
    margin-left: auto;
    padding-bottom: 10px;
    width: 32px;
    height: 32px;
    background-image: url(./../../../images/CloseIcon.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
}

.window-clients-control__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .window-clients-control__close-button {
        width: 20px;
        height: 20px;
        margin-right: 30px;
    }
}