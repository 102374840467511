
.formadd__content {
    width: 430px;
    display: flex;
    flex-direction: column;
    position: relative;
}

@media screen and (max-width: 450px) {
    .formadd__content {
        width: 282px;
    }
}