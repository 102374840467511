.search-form__img {
    background-color: transparent;
    width: 22px;
    /* padding-right: 30px; */
}

@media screen and (max-width: 769px) {
    .search-form__img {
        /* padding-right: 19px; */
    }
}

@media screen and (max-width: 582px) {
    .search-form__img {
        /* border-right: none; */
    }
}