.infotab__button {
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border: none;
    background-color: transparent !important;
    color: black;
    padding: 0 0 4px;
    margin-top: 24px;
    opacity: 1;
    transition: opacity 1s;
    cursor: pointer;
}


.infotab__button:nth-child(1) {
    display: flex;
    justify-content: right;
    width: 100%;
}
